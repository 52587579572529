import {useEffect, useState} from 'react'

export const useMediaQuery = (query: string) => {
	const [matches, setMatches] = useState(false)

	useEffect(() => {
		const mql = window.matchMedia(query)
		// Set the value on the initial render
		setMatches(mql.matches)

		function handleChange(e: MediaQueryListEvent) {
			setMatches(e.matches)
		}

		// Then subscribe to additional changes
		mql.addListener(handleChange)
		return () => mql.removeListener(handleChange)
	}, [query])

	return matches
}
