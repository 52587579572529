import React, {createContext, useContext, useEffect, useState} from 'react'

type CookiehubTyping = {
	openSettings: () => void
	hasAnswered: () => boolean
	hasConsented: (category: 'analytics' | 'marketing' | 'preferences') => boolean
}

const dummyHub = {
	openSettings: () => {},
	hasAnswered: () => false,
	hasConsented: () => false
}

const CookiehubContext = createContext<{hub: CookiehubTyping}>({hub: dummyHub})

export const CookiehubContextProvider: React.FC<{
	children: React.ReactNode
}> = ({children}) => {
	const [hub, setCookiehub] = useState<CookiehubTyping>()
	const [state, setState] = useState<string>('')

	const generateState = (hub: CookiehubTyping) => {
		return JSON.stringify([
			hub.hasAnswered(),
			hub.hasConsented('analytics'),
			hub.hasConsented('marketing'),
			hub.hasConsented('preferences')
		])
	}

	useEffect(() => {
		const interval = setInterval(() => {
			const globalHub: CookiehubTyping | undefined = (window as any).cookiehub
			if (!globalHub) return

			if (!hub) {
				setCookiehub(globalHub)
				setState(generateState(globalHub))
			} else {
				const globalState = generateState(globalHub)
				// Only trigger rewrite if the globalHub consents changed
				if (globalState !== state) {
					setCookiehub(globalHub)
					setState(generateState(globalHub))
				}
			}
		}, 500)
		return () => clearInterval(interval)
	}, [state])

	return (
		<CookiehubContext.Provider value={{hub: hub || dummyHub}}>
			{children}
		</CookiehubContext.Provider>
	)
}

export const useCookiehub = () => {
	return useContext(CookiehubContext).hub
}
